<template>
  <div class="text-left h-full">
    <div
      class="has-border-radius h-90p flex flex-col justify-center"
      :class="{
        'bg-gradient-primary-secondary-light': !pasiva,
        'bg-gradient-pasiva-light': pasiva
      }"
    >
      <b-image :src="image" class="pt-12" :class="imgClass" />
      <div class="flex justify-center items-center p-8 flex-col">
        <p class="text-2xl font-medium text-center">{{ titulo }}</p>
        <p class="mt-4 text-center text-sm font-light">{{ texto }}</p>
      </div>
    </div>
    <b-button @click="$router.back()" class="mt-4 w-1/2" outlined
      >Atras</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    pasiva: Boolean,
    image: String,
    titulo: String,
    texto: String,
    imgClass: String
  }
};
</script>
