<template>
  <div class="modal-has-overflow">
    <ValidationObserver
      ref="observer"
      v-slot="{ validate }"
      class="w-full text-left"
    >
      <div class="modal-card">
        <section class="modal-card-head pb-0">
          <p class="font-bold text-2xl">Especifica el rol</p>
          <button
            type="button"
            class="delete absolute right-0 mr-5"
            @click="$parent.close()"
          />
        </section>
        <section class="modal-card-body has-background-white text-left">
          <p class="mb-3">
            Antes de agregar a
            {{ persona2 }}
            , es necesario especificar su rol en la línea sobre
            {{ persona1 }}
          </p>
          <div class="columns is-multiline">
            <div class="column is-12">
              <valid-select
                rules="required"
                label="Rol"
                placeholder="Selecciona una opción..."
                v-model="rol"
                :expanded="true"
                :data="getRoles"
                show="nombre"
                realValue="id"
              />
            </div>
          </div>
        </section>
        <section class="modal-card-foot justify-end">
          <b-button @click="validate().then(result => setData(result))">
            Agregar persona
          </b-button>
        </section>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import ValidSelect from "@/components/form/ValidSelect";

export default {
  name: "ModalPersonaRol",
  props: {
    persona1: String,
    persona2: String
  },
  data() {
    return {
      rol: null
    };
  },
  components: {
    ValidationObserver,
    ValidSelect
  },
  computed: {
    ...mapGetters("catalogos", ["getRoles"])
  },
  methods: {
    setData(res) {
      if (!res) return;
      this.$emit("agregar", this.rol);
      this.$parent.close();
    }
  }
};
</script>
